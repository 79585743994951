@import "./global_variables.scss";

.input-md {
  // height: 42px !important;
  // font-size: 16px !important;
  height: 40px !important;
  padding: 8px 12px 9px; }

.header {
  display: block;
  position: relative;
  top: auto;
  left: auto;
  width: 100%;
  height: auto;
  padding: 0;
  background: #fff;
  border-bottom: 1px solid #dadada;
  @include box-shadow(0 1px 1px 0 rgba(0, 0, 0, 0.03));

  &.fixed {
    position: relative;
    top: auto;
    left: auto;
    height: auto;
    padding: 0;
    z-index: 800; }

  &.compras {
    height: 86px; }

  &.clear {
    background: none;
    border-bottom: 1px solid transparent;
    @include box-shadow(none); }
}

.h3-header {
  margin-top: 28px;

  &.small-margin {
    margin-bottom: 7px; }

  &.big-margin {
    margin-top: 28px; }
}

.caracteristica-wrapper {
  position: relative;
  float: left;
  width: 100%;

  &.has-unidade {
    width: 65%; }

  &.has-error > input {
    border: 1px solid red; }
}

.unidade-wrapper {
  position: relative;
  float: left;
  width: 35%;
  padding: 0 0 0 8px; }

.obrigatorio-label {
  color: #e43f3f; }

.older-button {
  font-size: 18px;
  background-color: #214986;
  border-color: #1e427a;

  &:hover {
    background-color: #025aa5; }

  &.small {
   font-size: 14px; }
}

.error-final {
  display: inline-block;
  margin-left: 30px;
  color: rgb(238, 38, 34);

  > i {
    font-size: 18px; }
}

.error-wrapper {
	position: relative;
	text-align: center;

	> i {
		font-size: 90px;
		color: #cbced3; }

	> h3 {
		margin-bottom: 20px; }

	> p {
		color: #909193; }
}

$border-color: #aeaeae;
$background-color: #f0f0f0;
$selected-color: #216ba5;
$highlighted-color: #3dcc4a;
$muted-color: #ccc;
$text-color: #000;

$font-size: 14px;
$border-radius: 0;
$item-size: 38px;
$day-margin: .166rem;
$triangle-size: 8px;
$datepicker__margin: .4rem;
$navigation-size: .45rem;

%triangle-arrow {
  margin-left: -$triangle-size;
  position: absolute;

  &,
  &::before {
    box-sizing: content-box;
    position: absolute;
    border: $triangle-size solid transparent;

    height: 0;
    width: 1px;
  }

  &::before {
    content: "";
    z-index: -1;
    border-width: $triangle-size;

    left: -$triangle-size;
    border-bottom-color: $border-color;
  }
}

%triangle-arrow-up {
  @extend %triangle-arrow;

  top: 0;
  margin-top: -$triangle-size;

  &,
  &::before {
    border-top: none;
    border-bottom-color: $background-color;
  }

  &::before {
    top: -1px;
    border-bottom-color: $border-color;
  }
}

%triangle-arrow-down {
  @extend %triangle-arrow;

  bottom: 0;
  margin-bottom: -$triangle-size;

  &,
  &::before {
    border-bottom: none;
    border-top-color: #fff;
  }

  &::before {
    bottom: -1px;
    border-top-color: $border-color;
  }
}

.react-datepicker-popper {
  z-index: 2000;
}

.react-datepicker {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: $font-size;
  background-color: #fff;
  color: $text-color;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  display: inline-block;
  position: relative;
  min-width: 300px;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker__tether-element-attached-top .react-datepicker__triangle {
  @extend %triangle-arrow-up;
}

.react-datepicker__tether-element-attached-bottom .react-datepicker__triangle {
  @extend %triangle-arrow-down;
}

.react-datepicker__tether-element-attached-bottom.react-datepicker__tether-element {
  margin-top: -20px;
}

.react-datepicker__header {
  text-align: center;
  background-color: $background-color;
  border-bottom: 1px solid $border-color;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  padding-top: 8px;
  position: relative;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px;
}

.react-datepicker__current-month {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: $font-size * 1.18;
}

.react-datepicker__navigation {
  line-height: $item-size;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  width: 0;
  border: $navigation-size solid transparent;
  z-index: 1;

  &--previous {
    left: 10px;
    border-right-color: $muted-color;

    &:hover {
      border-right-color: darken($muted-color, 10%);
    }
  }

  &--next {
    right: 10px;
    border-left-color: $muted-color;

    &:hover {
      border-left-color: darken($muted-color, 10%);
    }
  }

  &--years {
    position: relative;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &-previous {
      top: 4px;
      border-top-color: $muted-color;

      &:hover {
        border-top-color: darken($muted-color, 10%);
      }
    }

    &-upcoming {
      top: -4px;
      border-bottom-color: $muted-color;

      &:hover {
        border-bottom-color: darken($muted-color, 10%);
      }
    }
  }
}

.react-datepicker__month-container {
  display: inline;
  float: left;
  width: 100%;
}

.react-datepicker__month {
  margin: $datepicker__margin;
  text-align: center;
}

.react-datepicker__week-number {
  color: $muted-color;
  display: inline-block;
  width: $item-size;
  line-height: $item-size;
  text-align: center;
  margin: $day-margin;
}

.react-datepicker__day-name,
.react-datepicker__day {
  color: $text-color;
  display: inline-block;
  width: $item-size;
  line-height: $item-size;
  text-align: center;
  margin: $day-margin;
}

.react-datepicker__day {
  cursor: pointer;

  &:hover {
    border-radius: $border-radius;
    background-color: $background-color;
  }

  &--today {
    font-weight: bold;
  }

  &--highlighted {
    border-radius: $border-radius;
    background-color: $highlighted-color;
    color: #fff;

    &:hover {
      background-color: darken($highlighted-color, 5%);
    }
  }

  &--selected,
  &--in-selecting-range,
  &--in-range {
    border-radius: $border-radius;
    background-color: $selected-color;
    color: #fff;

    &:hover {
      background-color: darken($selected-color, 5%);
    }
  }

  &--keyboard-selected {
    border-radius: $border-radius;
    background-color: lighten($selected-color, 10%);
    color: #fff;

    &:hover {
      background-color: darken($selected-color, 5%);
    }
  }

  &--in-selecting-range:not(&--in-range) {
    background-color: rgba($selected-color, .5);
  }

  &--in-range:not(&--in-selecting-range) {
    .react-datepicker__month--selecting-range & {
      background-color: $background-color;
      color: $text-color;
    }
  }

  &--disabled {
    cursor: default;
    color: $muted-color;

    &:hover {
      background-color: transparent;
    }
  }
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view {
  border: 1px solid transparent;
  border-radius: $border-radius;

  &:hover {
    cursor: pointer;

    .react-datepicker__year-read-view--down-arrow,
    .react-datepicker__month-read-view--down-arrow {
      border-top-color: darken($muted-color, 10%);
    }
  }

  &--down-arrow {
    @extend %triangle-arrow-down;
    border-top-color: $muted-color;
    float: right;
    margin-left: 20px;
    top: 8px;
    position: relative;
    border-width: $navigation-size;
  }

}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown {
  background-color: $background-color;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  text-align: center;
  border-radius: $border-radius;
  border: 1px solid $border-color;

  &:hover {
    cursor: pointer;
  }

  &--scrollable {
    height: 150px;
    overflow-y: scroll;
  }
}

.react-datepicker__year-option,
.react-datepicker__month-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:first-of-type {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  &:last-of-type {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  &:hover {
    background-color: $muted-color;

    .react-datepicker__navigation--years-upcoming {
      border-bottom-color: darken($muted-color, 10%);
    }

    .react-datepicker__navigation--years-previous {
      border-top-color: darken($muted-color, 10%);
    }
  }

  &--selected {
    position: absolute;
    left: 15px;
  }
}

.react-datepicker__close-icon {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: inline-block;
  height: 0;
  outline: 0;
  padding: 0;
  vertical-align: middle;

  &::after {
    background-color: $selected-color;
    border-radius: 50%;
    bottom: 0;
    box-sizing: border-box;
    color: #fff;
    content: "\00d7";
    cursor: pointer;
    font-size: 12px;
    height: 16px;
    width: 16px;
    line-height: 1;
    margin: -8px auto 0;
    padding: 2px;
    position: absolute;
    right: 7px;
    text-align: center;
    top: 50%;
  }
}

.react-datepicker__today-button {
  background: $background-color;
  border-top: 1px solid $border-color;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

.react-datepicker__tether-element {
  z-index: 2147483647;
}

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647;

  .react-datepicker__day-name,
  .react-datepicker__day {
    width: 3rem;
    line-height: 3rem;
  }

  // Resize for small screens
  @media (max-width: 400px), (max-height: 550px)  {
    .react-datepicker__day-name,
    .react-datepicker__day {
      width: 3rem;
      line-height: 3rem;
    }
  }

  .react-datepicker__current-month {
    font-size: $font-size * 1.8;
  }

  .react-datepicker__navigation {
    border: 1.8 * $navigation-size solid transparent;
  }

  .react-datepicker__navigation--previous {
    border-right-color: $muted-color;

    &:hover {
      border-right-color: darken($muted-color, 10%);
    }
  }

  .react-datepicker__navigation--next {
    border-left-color: $muted-color;

    &:hover {
      border-left-color: darken($muted-color, 10%);
    }
  }
}

.form-control-date-wrapper {
	position: relative;
  max-width: 158px;
  background-color: #fff;

	> i {
		position: absolute;
		top: 13px;
		left: 13px;
		color: #aaa;	}
}

.form-control-date {
	display: block;
	width: 100%;
	height: 40px;
	padding: 9px 12px 10px 37px;
	font-size: 14px;
	line-height: 1.42857;
	color: #555555;
	background: none;
	background-image: none;
	border: 1px solid #ccc;
	border-radius: 0px;
	-webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
	-o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
	transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s; }

.form-control-date:focus {
	border-color: #66afe9;
	outline: 0;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }

.result-li {
  outline: none;

  &.selected {
    text-decoration: none;
    color: #262626;
    background-color: #f5f5f5 !important; }
}








































.header-tab-wrapper {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  white-space: nowrap;

  // &:after {
  //   background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, #fff 100%);
  //   background: linear-gradient(to right, rgba(255,255,255,0) 0%, #fff 100%);
  //   content: '';
  //   height: 100%;
  //   position: absolute;
  //   right: 15px;
  //   top: 0;
  //   width: 2rem; }
}

.header-tab-item {
  display: inline-block;
  position: relative;
  // float: left;
  width: auto;
  margin: 15px 20px 0 0;
  padding: 0 4px 10px;
  border: none;
  border-bottom: 3px solid transparent;
  background: none;
  outline: none;
  color: #b3aead;

  > span {
    color: #ffffff;
    background: #d1d3d7;
    @include border-radius(60px);
    margin-left: 2px;
    padding: 3px 5px 2px;
    font-size: 12px;
    font-weight: 700; }

  &:hover {
    color: #999;
    border-bottom: 3px solid #ccc; }

  &.active {
    color: #222;
    border-bottom: 3px solid #13aecb;

    > span {
      background: #13aecb; }
  }
}

.header-buttons-wrapper {
  text-align: right;
  padding-top: 30px; }

.header-tab-item-division {
  display: none; }


/* ================================================================== *\


    @media (min-width: 768px) and (max-width: 1024px) {

    #Small devices (tablets, 768px and up)



\* ================================================================== */ @media (min-width: 768px) {


.header {
  &.fixed {
    position: fixed;
    top: $top_height * 1px;
    left: 0;
    height: $top_fixed_header_height * 1px;
    padding: 0 0 0 $closed_width * 1px; }

  &.compras {
    height: $top_fixed_header_height - 14 * 1px; }
}

.h3-header {
  margin-top: 36px;

  &.big-margin {
    margin-top: 46px; }
}

.header-tab-item {
  display: inline-block;
  position: relative;
  float: left;
  width: auto;
  margin: 15px 40px 0 0;
  padding: 0 4px 10px;
  border: none;
  border-bottom: 3px solid transparent;
  background: none;
  outline: none;
  color: #b3aead;

  > span {
    color: #ffffff;
    background: #d1d3d7;
    @include border-radius(60px);
    margin-left: 2px;
    padding: 3px 5px 2px;
    font-size: 12px;
    font-weight: 700; }

  &:hover {
    color: #999;
    border-bottom: 3px solid #ccc; }

  &.active {
    color: #222;
    border-bottom: 3px solid #13aecb;

    > span {
      background: #13aecb; }
  }
}

.header-tab-item-division {
  display: inline-block;
  position: relative;
  float: left;
  width: 1px;
  height: 47px;
  margin: 0 40px 0 0;
  background: #e8eaec;
  background: -moz-linear-gradient(top, #ffffff 0%, #e8eaec 60%);
  background: -webkit-linear-gradient(top, #ffffff 0%,#e8eaec 60%);
  background: linear-gradient(to bottom, #ffffff 0%,#e8eaec 60%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e8eaec',GradientType=0 ); }








/* ================================================================== *\



    #Medium devices (desktops, 992px and up)



\* ================================================================== */ } @media (min-width: 992px) {





/* ================================================================== *\



    #Large devices (large desktops, 1200px and up)



\* ================================================================== */ } @media (min-width: 1200px) {





/* ================================================================== */ } /* END devices */
