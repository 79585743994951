@import "../../../styles/global_variables.scss";

.review-block {
	position: relative;
	display: flex;
	flex-direction: column;
	min-height: 142px;
	margin: 0 0 220px 0;
	padding: 0;
	background: #F8F8F8;
	-webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
	@include media-size(425) {
		display: block; }

	&:after {
		content: "";
		display: table;
		clear: both; }

	> .content {
		position: relative;
		float: left;
		width: 100%;
		padding: 0px;
		min-height: auto;
		width: 100%;
		padding: 25px 2px 0;
		min-height: auto;

		@include media-size(425) {
			width: 50%;
			min-height: 400px;
			padding: 26px 30px 30px; }

		> label {}
		display: block; } }

.info-title {
	position: relative;
	float: left;
	width: 100%;
	min-height: 56px;
	padding: 19px 30px 0;
	background: #0D3E60;
	border-bottom: 1px solid #daddde;
	text-transform: uppercase;
	color: #fff; }

.empresa-input {
	position: relative;
	float: left;
	width: 100%; }

.cadastrar {
	width: 100%;
	position: absolute;
	top: 3px;
	left: -5px;
	@include media-size(425) {
		position: static; } }



.empresa-cnpj {
	position: relative;
	float: left;
	width: 100%;

	&.full {
		width: 100%; } }

