@import "../../../styles/global_variables.scss";

.container {
  position: relative;
  float: left;
  width: 100%;
  border: #f2994a 1px solid;
  background-color: #f8f8f8;
  padding: 30px;
  min-height: 200px;
  height: auto;
  margin-bottom: 20px;

  .title {
    position: absolute;
    top: 0px;
    font-weight: bold;
    color: #f2994a;
    font-size: 18px;
  }

  .body {
    padding-top: 30px;
    min-height: 70px;
    height: auto;
    width: 100%;
		word-wrap: break-word;

    .message {
      color: #000;
      white-space: pre-wrap;
    }
  }

  .buttons {
    display: flex;
    top: 5px;
    position: relative;
    float: right;
    .not-correcation {
      border: #a0a0a1 1px solid;
      background-color: #fff;
      color: #000;
      padding: 12px;
      min-height: 45px;
      margin-right: 10px;
      font-weight: 600;
    }
    .correction {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      background-color: #0d3e60;
      color: #fff;
      font-weight: 600;
      border: none;
      padding: 12px;
      min-height: 43px;
      min-width: 132px;
    }
  }
}

.modal-content {
	max-width: 640px;
	margin: 0 auto;
	border-radius: 6px;
  border: none !important;
  padding: 0 0 25px;

	&:after {
		content: '';
		display: table;
		clear: both; }
}

.modal-body {
	position: relative;
	padding: 30px 40px 40px;
	text-align: center;

	.content {
    position: relative;
    display: flex;
    flex-direction: column;
		float: left;
		width: 100%;
		text-align: left;
		.textarea {
			margin: 0 0 30px; }
		.warning {
      position: relative;
      color: red;
      top: -10px;
    }
	 	.title {
			margin: 0 0 16px;
      font-size: 18px; }
      > div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
	}
}

.revisao-container {
  position: relative;
  background-color: #f2994a;
  height: 50px;

  .revise-title {
    padding: 15px;
    width: 200px;
    .title {
      color: #fff;
      font-weight: 600;
    }
  }
  .main {
    width: auto;
    position: absolute;
    left: 33%;
    top: 15px;
    .text-icon {
      > i {
        position: absolute;
        color: #fff;
        font-size: 30px;
        left: -35px;
        top: -5px;
      }
      .main-text {
        color: #fff;
        font-weight: 600;
      }
    }
  }
  > span {
    position: relative;
    top: -41px;
    left: -10px;
    line-height: 10px;
    font-size: 14px;
    float: right;
    cursor: pointer;
    color: #333333;
    font-weight: bold;
    border-bottom: 1px solid #333333;
  }
}

/* ================================================================== *\
 @media mobile max-widht 320px;
 \* ================================================================== */
@media (max-width: 320px) {
  .container {
    width: 300px;
    min-height: 280px;
    height: auto;
    .buttons {
      top: 5px;
      left: 34px;
      position: relative;
      .not-correcation {
        width: 280px;
      }
      .correction {
        margin-top: 5px;
        width: 280px;
        min-width: 132px;

      }
    }
  }
  .revisao-container {
    width: 300px;
    height: 100px;
    .main {
      left: 20px;
      .text-icon {
      }
    }
    > span {
      position: relative;
      top: 10px;
    }
  }
}

/* ================================================================== *\


		@media (min-width: 768px) and (max-width: 1024px) {

    #Small devices (tablets, 768px and up)



\* ================================================================== */
@media (max-width: 768px) {
  .revisao-container {

    .main {
      .text-icon {
        position: absolute;
        left: 25px !important;
        top: -10px;
      }
    }
    > span {
      position: relative;
      top: -30px;
      left: -50px;
    }
  }
  /* ================================================================== *\



    #Medium devices (desktops, 992px and up)



\* ================================================================== */
}
@media (min-width: 992px) {
  /* ================================================================== *\



		#Large devices (large desktops, 1200px and up)



\* ================================================================== */
}
@media (max-width: 1024px) {
  /* ================================================================== */
  .revisao-container {
    .main {
      .text-icon {
        position: relative;
        left: 126px;
        top: -10px;
      }
    }
    > span {
      position: relative;
      top: -30px;
      left: -50px;
    }
  }
} /* END devices */
