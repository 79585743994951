// Sizes
$opened_width:             220;
$closed_width:             70;
$closed_width_mobile:      60;
$top_height:               60;
$actions_bar_height:       60; // 46
$search_bar_height:        90;
$top_fixed_header_height:  120;
$tabs_height:              60;
$compras_row_height:       73;
$itens_column_width:       370;

// Colors
$grey_background:          #f1f3f6; // f1f3f6 f5f6f9
$menu_background:          #1c3c53; // 364156 2f3d4f 2f3c4c 2b303b 062d47 2e3f57
$green:                    #00b963;
$yellow:                   #ffd25c;
// #1c3960   233e64   #243a58
$s_cinza: #dcdcdc;
$s_amarelo: #f7cf66;
$s_azul: #63a4d0;
$s_azul_escuro: #4c5d70;
$s_verde: #7dbc81;
$s_vermelho: #e26262;

// Z-index's
$zindex_topbar:            1010 !default;
$zindex_leftbar:           1030 !default;




/* ================================================================== *\



    #Mixins



\* ================================================================== */

@mixin transition-one($transition1) {
    -webkit-transition: $transition1;
       -moz-transition: $transition1;
         -o-transition: $transition1;
        -ms-transition: $transition1;
            transition: $transition1;
}
@mixin transition-two($transition1, $transition2) {
    -webkit-transition: $transition1, $transition2;
       -moz-transition: $transition1, $transition2;
         -o-transition: $transition1, $transition2;
        -ms-transition: $transition1, $transition2;
            transition: $transition1, $transition2;
}
@mixin transition-three($transition1, $transition2, $transition3) {
    -webkit-transition: $transition1, $transition2, $transition3;
       -moz-transition: $transition1, $transition2, $transition3;
         -o-transition: $transition1, $transition2, $transition3;
        -ms-transition: $transition1, $transition2, $transition3;
            transition: $transition1, $transition2, $transition3;
}
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}
@mixin box-shadow($shadows) {
  -webkit-box-shadow: $shadows;
     -moz-box-shadow: $shadows;
          box-shadow: $shadows;
}
@mixin user-select() {
   -webkit-user-select:none;
    -khtml-user-select:none;
      -moz-user-select:-moz-none;
       -ms-user-select:none;
           user-select:none;
}
@mixin background-size($sizes) {
  -webkit-background-size: $sizes;
     -moz-background-size: $sizes;
       -o-background-size: $sizes;
          background-size: $sizes;
}

@mixin media-size($size) {
  
  @if $size == 320 {
		@media (min-width: 320px) {
			@content;
		}
  }

	@if $size == 425 {
		@media (min-width: 425px) {
			@content;
		}
  }

  @if $size == 600 {
		@media (min-width: 600px) {
			@content;
		}
  }

	@if $size == 768 {
		@media (min-width: 768px) {
			@content;
		}
	}

	@if $size == 992 {
		@media (min-width: 992px) {
			@content;
		}
	}

	@if $size == 1200 {
		@media (min-width: 1200px) {
			@content;
		}
  }
}
