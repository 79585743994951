.pagination {
  display: flex;
  justify-content: center;
  width: 100%;
}


.all-pages {
  border: none!important;
  outline: none!important;
  background-color: transparent!important;
}
.current-page {
  border: 1px solid #4884be!important;
  outline: none!important;
  background-color: transparent!important;
  margin: 0 1px;
  transition: 0.5;
} 

.all-pages:hover, .all-pages:active {
  background-color: transparent!important;
}