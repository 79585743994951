.container-impressao {
	width: 60%;

	@media print {
		width: 100%;
	}

	padding: 30px;
	margin: 3% auto;
	background: white;

	header {
		width: 100%;
		position: relative;
		height: auto;
		border-bottom: 3px solid #BCBCBC;
		margin-bottom: 50px;

		// section { width: 50%; }

		.title-impressao {
			display: block;
			font-weight: 500;
			font-size: 23px;
			line-height: 27px;
			margin-bottom: 10px;
			margin-right: 0;
		}

		.header-left {
			float: left;
			p {
				font-size: 15px;
				line-height: 18px;
				margin-bottom: 0px;
			}

			span { margin: 0 10px; }
		}

		.header-right {
			position: absolute;
			bottom: 0;
			right: 0;
			font-size: 13px;
			line-height: 17px;
			text-align: right;

			p { margin-top: 15px }
		}
	}

	.faturamento {
		width: 50%;
		float: left;
		border-bottom: 3px solid #EAEAEA;
		padding-bottom: 30px;
		margin-bottom: 30px;

		p { margin-bottom: 5px }

		span { font-weight: 500 }
	}

	.resumo {
		width: 45%;
		margin-left: 30px;
		float: right;
		padding: 42px 45px 74px 45px;
		background-color: rgba(234, 234, 234, 0.33) !important;

		> div {
			margin-top: 39px;
			margin-bottom: 23px;
			font-size: 15px;
			line-height: 18px;
			color: #343434;
			border-bottom: 2px solid #C9C9C9;
		}

		span {
			font-weight: 500;
			// font-size: 17px;
			line-height: 20px;
			color: #000000;
		}

		p {
			span {
				float: right;
				font-weight: 500;
				font-size: 20px;
				line-height: 23px;
				color: #0C6A9B;
			}

			b { float: right }
		}
	}

	.fornecedor {
		width: 50%;
		p { margin-bottom: 5px }
		span { font-weight: 500 }
	}

	.observacoes { width: 100% }

	.item-section {
		width: 100%;
		border: 1px solid #EAEAEA;
		margin-bottom: 20px;

		.item-left {
			display: inline-block;
			width: 60%;
			padding: 25px;
			vertical-align: top;

			&.strikethrough {
				div:first-child, p {
					text-decoration-line: line-through;
					opacity: 0.4;
				}
			}

			.retirado {
				width: fit-content;
				background: #474747 !important;
				font-size: 13px;
				padding: 5px 10px;
				border-radius: 10px;
				color: white !important;
			}

			p {
				font-weight: 500;
				span { font-weight: 400 }
			}

			> div {
				font-size: 16px;
				line-height: 19px;
				font-weight: 500;
				margin-bottom: 10px;
			}
		}

		.item-right {
			width: 40%;
			padding: 25px 35px;
			display: inline-block;
			border-left: 1px solid #EAEAEA;

			&.strikethrough {
				section:first-child, p, p > b, p > span {
					text-decoration-line: line-through;
					opacity: 0.4;
				}
			}

			> section:first-child {
				font-size: 16px;
				line-height: 19px;
				font-weight: 500;
				margin-bottom: 10px;
			}

			p > b { float: right }

			span {
				float: right;
				font-weight: 500;
				font-size: 19px;
				line-height: 22px;
				color: #0C6A9B;
			}
		}
	}

	hr {
		margin-left: 0px;
		border-top: 1px solid lightgrey;
		&.small { width: 5% }
		&.normal { width: 10% }
		&.large { width: 15% }
		&.full {
			width: 100%;
		}
	}

	.hr-text {
		background-color: inherit;
		position: relative;
		width: 90px;
		height: 30px;
		@media print {
			margin: 300px auto 10px auto
		}
		margin: 10px auto 10px auto;
		font-size: 15px;
		text-align: center;
	}
}

.clearfix {
	display: inline-block;
	&:after {
		visibility: hidden;
		display: block;
		font-size: 0;
		content: " ";
		clear: both;
		height: 0;
	}
}

